<template>
  <div
    v-if="button"
    id="buttonModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="buttonModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog sx-modal-responsive"
      :style="{
        width: String.format(
          '{0}%',
          String.isNullOrWhiteSpace(button.lightBoxRatio) ||
            button.lightBoxRatio == 0
            ? defaultLightBoxRatio
            : button.lightBoxRatio
        ),
        'min-width': String.format(
          '{0}%',
          String.isNullOrWhiteSpace(button.lightBoxRatio) ||
            button.lightBoxRatio == 0
            ? defaultLightBoxRatio
            : button.lightBoxRatio
        ),
      }"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-capitalize">
            <i
              :class="button.icon"
              :style="{ color: button.iconColor }"
              v-if="button.iconColor"
            ></i>
            <i :class="button.icon" v-else></i>
            {{ button.name }}
          </h5>
          <span
            class="badge badge-info ms-2 fs-6"
            v-if="button && button.isPageRefreshAfterProcessDone"
            ><i class="fa fa-cog text-white me-1 fs-6"></i
            >{{
              $t(
                "BaseModelFields.PageRefreshedAfterProcess",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</span
          >
          <button
            type="button"
            class="btn-close"
            @click="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <template v-if="isLoading">
            <div id="loading-bar">
              <div
                class="d-flex align-items-center justify-content-center h-100 w-100"
              >
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <b
                class="d-flex align-items-center mt-2 mb-0 justify-content-center font-weight-normal"
                >{{
                  $t(
                    "BaseModelFields.PleaseWait",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</b
              >
            </div>
          </template>
          <template
            v-if="
              !String.isNullOrWhiteSpace(message) && btValidations.length == 0
            "
          >
            <div
              v-if="notificationType == 'Tooltip'"
              class="alert mb-1"
              :class="{
                'alert-success': isOk,
                'alert-danger': !isOk,
              }"
            >
              <template v-if="isOk">
                {{
                  this.$t(
                    "BaseModelFields.SuccessMessage",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </template>
              <template v-else>
                {{ message }}
              </template>
            </div>
          </template>
          <template v-if="!isLoading">
            <BusinessRuleResponseGrid
              :errors="businessRuleResponses"
              :response="{
                isOk: isOk,
                message: message,
                notificationType: notificationType,
              }"
            />
          </template>
          <div
            class="alert alert-danger mb-1"
            v-for="(item, i) in btValidations"
            :key="i"
          >
            {{ item.message }}
          </div>
          <div class="iframe-block"></div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger btn-sm"
            @click="close"
            data-bs-dismiss="modal"
          >
            <i class="fas fa-times"></i>
            {{
              $t(
                "BaseModelFields.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BusinessRuleResponseGrid from "@/components/custom/set-pages/BusinessRuleResponseGrid.vue";
export default {
  name: "ButtonModal",
  props: [
    "button",
    "isLoading",
    "isOk",
    "notificationType",
    "message",
    "businessRuleResponses",
    "buttonValidations",
  ],
  data() {
    return {
      btValidations: [],
      defaultLightBoxRatio: 70,
    };
  },
  components: {
    BusinessRuleResponseGrid,
  },
  watch: {
    buttonValidations: {
      deep: true,
      handler() {
        this.btValidations = this.buttonValidations;
      },
    },
  },
  methods: {
    close() {
      if (this.button && this.button.isPageRefreshAfterProcessDone) {
        window.location.reload();
      }
    },
  },
};
</script>
